import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const GET_VISTA_STUDIO_SEARCH_RESULTS = '[FlightRequest.SearchResults] Get Vista Studio Search Results';
export const GET_VISTA_STUDIO_SEARCH_RESULTS_SUCCESS = '[FlightRequest.SearchResults] Get Vista Studio Search Results Success';
export const GET_VISTA_STUDIO_SEARCH_RESULTS_FAILED = '[FlightRequest.SearchResults] Get Vista Studio Search Results Failed';
export const GET_MARKETPLACE_SEARCH_RESULTS = '[FlightRequest.SearchResults] Get Marketplace Search Results';
export const GET_MARKETPLACE_SEARCH_RESULTS_SUCCESS = '[FlightRequest.SearchResults] Get Marketplace Search Results Success';
export const GET_MARKETPLACE_SEARCH_RESULTS_FAILED = '[FlightRequest.SearchResults] Get Marketplace Search Results Failed';
export const SET_LOOKUP = '[FlightRequest.SearchResults] Set Lookup';
export const RESET_LOOKUP = '[FlightRequest.SearchResults] Reset Lookup';


export class GetVistaStudioSearchResultsAction implements Action {
    readonly type = GET_VISTA_STUDIO_SEARCH_RESULTS;
}

export class GetVistaStudioSearchResultsSuccessPayload {
  constructor(public items: Array<models.VistaStudioSearchResult>) {}
}

export class GetVistaStudioSearchResultsSuccessAction implements Action {
  readonly type = GET_VISTA_STUDIO_SEARCH_RESULTS_SUCCESS;

  constructor(public payload: GetVistaStudioSearchResultsSuccessPayload) {}
}

export class GetVistaStudioSearchResultsFailedAction implements Action {
  readonly type = GET_VISTA_STUDIO_SEARCH_RESULTS_FAILED;
}

export class GetMarketplaceSearchResultsAction implements Action {
  readonly type = GET_MARKETPLACE_SEARCH_RESULTS;
}

export class GetMarketplaceSearchResultsSuccessPayload {
  constructor(public items: Array<models.MarketplaceSearchResult>) {}
}

export class GetMarketplaceSearchResultsSuccessAction implements Action {
  readonly type = GET_MARKETPLACE_SEARCH_RESULTS_SUCCESS;

  constructor(public payload: GetMarketplaceSearchResultsSuccessPayload) {}
}

export class GetMarketplaceSearchResultsFailedAction implements Action {
  readonly type = GET_MARKETPLACE_SEARCH_RESULTS_FAILED;
}

export class SetLookupAction implements Action {
  readonly type = SET_LOOKUP;

  constructor(public payload: viewModels.SearchResultsLookup) {}
}

export class ResetLookupAction implements Action {
  readonly type = RESET_LOOKUP;
}

export type Actions = 
  | GetVistaStudioSearchResultsAction
  | GetVistaStudioSearchResultsSuccessAction
  | GetVistaStudioSearchResultsFailedAction
  | GetMarketplaceSearchResultsAction
  | GetMarketplaceSearchResultsSuccessAction
  | GetMarketplaceSearchResultsFailedAction
  | SetLookupAction
  | ResetLookupAction;
