import { IntacctIntegration } from './intacct-integration';
import { ArgusRating } from './rating';
import { WyvernRating } from './rating';
import { IsBaoRating } from './rating';
import { VendorContact } from './vendor-contact';
import { SapIntegration } from './sap-integration';
import { OperatorDocument } from './operator-document';
import { VendorProspectus } from './vendor-prospectus';
import { ServiceArea } from './service-area';
import { LevelFlightRating } from './rating';
import { WyvernCertificate } from './wyvern-certificate';
import { Aircraft } from './aircraft';
import { BrokerRate } from './broker-rate';
import { OfferExpirationTime } from './offer-expiration-time';
import { AutomatedOfferCreateConfiguration } from './automated-offer-create-configuration';
import { VendorOrganization } from './vendor-organization';

export class Vendor {
  id: string = null;
  aircraftOperatorId: number = null;
  intacctIntegration: IntacctIntegration = new IntacctIntegration();
  sapIntegration: SapIntegration = new SapIntegration();
  name: string = null;
  legalName: string = null;
  active: boolean = null;

  // operator specific (optional fields)
  argusRating: ArgusRating = new ArgusRating();
  wyvernRating: WyvernRating = new WyvernRating();
  isBaoRating: IsBaoRating = new IsBaoRating();
  dedicatedFleet: boolean = null;
  trusted: boolean = null;
  wyvernId: string = null;
  auditExpirationDate: Date = null;
  levelFlightRating: LevelFlightRating = new LevelFlightRating();
  vendorContact: VendorContact = new VendorContact();
  automatedSourcingForGuaranteed = false;
  note: string = null;
  externalNote: string = null;
  cancellationTerms: string = null;
  offFleetPartner = false;
  operatorDocuments: Array<OperatorDocument> = [];
  organization: VendorOrganization = new VendorOrganization();
  prospectus: VendorProspectus = new VendorProspectus();
  serviceAreas: Array<ServiceArea> = [];
  automatedSourcingForNonGuaranteed = false;
  aocExpirationDate: Date = null;
  aircraftsRegistrationCountryCode: string = null;
  wyvernCertificate: WyvernCertificate = new WyvernCertificate();
  aircraft: Array<Aircraft> = [];
  brokerRates: Array<BrokerRate> = [];
  offerExpirationTime: Array<OfferExpirationTime> = [];
  automatedOfferCreateConfigurations: Array<AutomatedOfferCreateConfiguration> = [];
  draftCancellationTerms: string = null;
  operatorApprovalStatus: string = null;
  operatorAgreement: boolean = null;
  blanketCrewApproval: boolean = false;
  peakDates: Array<Date> = [];
}
