import { OperatorApprovalStatusEnum } from "../../domain/models";

export class VendorLookup {
  skip = 0;
  limit = 15;
  active: boolean = null;
  searchTerm: string = null;
  prospectusApproved: boolean = null;
  automatedSourcingForGuaranteed: boolean = null;
  automatedSourcingForNonGuaranteed: boolean = null;
  aircraftsRegistrationCountryCode: string = null;
  operatorApprovalStatus: OperatorApprovalStatusEnum = null;
  regionIds: Array<number> = [];
}
