import { SortDescriptor } from '@progress/kendo-data-query';
import { Lookup } from './lookup';

export class UsersLookup extends Lookup {
  skip = 0;
  limit = 15;
  total = 0;
  active = true;
  sort: Array<SortDescriptor> = [{dir: 'asc', field: 'firstName'}];
}
