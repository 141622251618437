import { User } from './user';
import { Status } from './status';
import { LegRequest } from './leg-request';
import { AircraftCategory } from './aircraft-category';
import { OperatorPurchaseOffer } from './operator-purchase-offer';
import { PriceTypeEnum } from './price-type-enum';
import { SalesAdvisor } from './sales-advisor';
import { ClientDetails } from './client-details';
import { Contact } from './contact';
import { CurrencyEnum } from '../../../../shared/models';
import { SearchResultOption } from './search-result-option';
import { FulfillmentStagesEnum } from './fulfillment-stages-enum';
import { RtpRequestStage } from './rtp-request-stage';

export class SourcingRequest {
  sourcingRequestId: string;
  parentSourcingRequestId: string;
  maxPrice: number = null;
  flightPrice: number = null;
  createdUser: User;
  aircraftCategories: Array<AircraftCategory>;
  lastModifiedDate: Date;
  lastModifiedUser: User;
  createdDate: Date;
  status: Status;
  legs: Array<LegRequest>;
  operatorPurchaseOffers: Array<OperatorPurchaseOffer>;
  dba: string = null;
  opportunityId: string;
  priceType: PriceTypeEnum = null;
  flightRequestId: number;
  podContact: Contact;
  ownerContact: Contact;
  salesAdvisor: SalesAdvisor;
  clientDetails: ClientDetails;
  offersRequireStaffApproval: boolean;
  sendToXOMarketplace: boolean;
  elacProForma: boolean;
  petOnBoard: boolean;
  petNote: string;
  currency: CurrencyEnum = null;
  offerPriceInCurrency: number = null;
  currencyUpdateTime?: Date = null;
  currencyRate?: number = null;
  allowFirmDeparture: boolean = null;
  searchResultOptions: Array<SearchResultOption>;
  additionalInstructions: string;
  quoteDescription: string;
  childSourcingRequests: Array<SourcingRequest>;
  source: string;
  fulfillmentStage: FulfillmentStagesEnum;
  isModeratedOffersSent: boolean;
  rtpRequestStages: Array<RtpRequestStage>;

  getDba(): string {
    return this.dba;
  }

  setDba(dba: string): SourcingRequest {
    this.dba = dba;

    return this;
  }

  getSourcingRequestId(): string {
    return this.sourcingRequestId;
  }

  setSourcingRequestId(sourcingRequestId: string): SourcingRequest {
    this.sourcingRequestId = sourcingRequestId;
    return this;
  }

  getParentSourcingRequestId(): string {
    return this.parentSourcingRequestId;
  }

  setParentSourcingRequestId(parentSourcingRequestId: string): SourcingRequest {
    this.parentSourcingRequestId = parentSourcingRequestId;

    return this;
  }

  getMaxPrice(): number {
    return this.maxPrice;
  }

  setMaxPrice(maxPrice: number): SourcingRequest {
    this.maxPrice = maxPrice;

    return this;
  }

  getFlightPrice(): number {
    return this.flightPrice;
  }

  setFlightPrice(flightPrice: number): SourcingRequest {
    this.flightPrice = flightPrice;
    
    return this;
  }

  getLegs(): Array<LegRequest> {
    return this.legs;
  }

  setLegs(legs: Array<LegRequest>): SourcingRequest {
    this.legs = legs;
    return this;
  }

  getLastModifiedDate(): Date {
    return this.lastModifiedDate;
  }

  setLastModifiedDate(lastModifiedDate: Date): SourcingRequest {
    this.lastModifiedDate = lastModifiedDate;
    return this;
  }

  getLastModifiedUser(): User {
    return this.lastModifiedUser;
  }

  setLastModifiedUser(lastModifiedUser: User): SourcingRequest {
    this.lastModifiedUser = lastModifiedUser;
    return this;
  }

  getCreatedDate(): Date {
    return this.createdDate;
  }

  setCreatedDate(createddDate: Date): SourcingRequest {
    this.createdDate = createddDate;
    return this;
  }

  getCreatedUser(): User {
    return this.createdUser;
  }

  setCreatedUser(createdUser: User): SourcingRequest {
    this.createdUser = createdUser;
    return this;
  }

  getOperatorPurchaseOffers(): Array<OperatorPurchaseOffer> {
    return this.operatorPurchaseOffers;
  }

  setOperatorPurchaseOffers(operatorPurchaseOffers: Array<OperatorPurchaseOffer>) {
    this.operatorPurchaseOffers = operatorPurchaseOffers;
    return this;
  }

  getStatus(): Status {
    return this.status;
  }

  setStatus(status: Status): SourcingRequest {
    this.status = status;
    return this;
  }

  getOpportunityId(): string {
    return this.opportunityId;
  }

  setOpportunityId(opportunityId: string): SourcingRequest {
    this.opportunityId = opportunityId;
    return this;
  }

  getPriceType(): PriceTypeEnum {
    return this.priceType;
  }

  setPriceType(priceType: PriceTypeEnum): SourcingRequest {
    this.priceType = priceType;
    return this;
  }

  getAircraftCategories(): Array<AircraftCategory> {
    return this.aircraftCategories;
  }

  setAircraftCategories(categories: Array<AircraftCategory>): SourcingRequest {
    this.aircraftCategories = categories;
    return this;
  }

  getAircraftCategoryNames(): string {
    if (this.aircraftCategories && this.aircraftCategories.length) {
      return this.aircraftCategories.map((category) => category.name).join(', ');
    } else {
      return null;
    }
  }

  getLegsIcaoCodes(): string {
    if(this.legs && this.legs.length) {
      return this.legs.map(leg => `${leg.getOriginIcaoCode()} - ${leg.getDestinationIcaoCode()}`).join(', ');
    } else {
      return null;
    }
  }

  getFlightRequestId(): number {
    return this.flightRequestId;
  }

  setFlightRequestId(flightRequestId: number): SourcingRequest {
    this.flightRequestId = flightRequestId;
    return this;
  }

  getPodContact(): Contact {
    return this.podContact;
  }

  setPodContact(podContact: Contact): SourcingRequest {
    this.podContact = podContact;

    return this;
  }

  getOwnerContact(): Contact {
    return this.ownerContact;
  }

  setOwnerContact(ownerContact: Contact): SourcingRequest {
    this.ownerContact = ownerContact;

    return this;
  }

  getSalesAdvisor(): SalesAdvisor {
    return this.salesAdvisor;
  }

  setSalesAdvisor(salesAdvisor: SalesAdvisor): SourcingRequest {
    this.salesAdvisor = salesAdvisor;

    return this;
  }

  getClientDetails(): ClientDetails {
    return this.clientDetails;
  }

  setClientDetails(clientDetails: ClientDetails): SourcingRequest {
    this.clientDetails = clientDetails;

    return this;
  }

  getOffersRequireStaffApproval(): boolean {
    return this.offersRequireStaffApproval;
  }

  setOffersRequireStaffApproval(offersRequireStaffApproval: boolean): SourcingRequest {
    this.offersRequireStaffApproval = offersRequireStaffApproval;

    return this;
  }

  getSendToXOMarketplace(): boolean {
    return this.sendToXOMarketplace;
  }

  setSendToXOMarketplace(sendToXOMarketplace: boolean): SourcingRequest {
    this.sendToXOMarketplace = sendToXOMarketplace;
    return this;
  }

  getElacProForma(): boolean {
    return this.elacProForma;
  }

  setElacProForma(elacProForma: boolean): SourcingRequest {
    this.elacProForma = elacProForma;

    return this;
  }

  setPetOnBoard(petOnBoard: boolean): SourcingRequest {
    this.petOnBoard = petOnBoard;

    return this;
  }

  getPetOnBoard(): boolean {
    return this.petOnBoard;
  }

  setPetNote(petNote: string): SourcingRequest {
    this.petNote = petNote;

    return this;
  }

  getPetNote(): string {
    return this.petNote;
  }

  getOfferPriceInCurrency(): number {
    return this.offerPriceInCurrency;
  }

  setOfferPriceInCurrency(price: number): SourcingRequest {
    this.offerPriceInCurrency = price;
    return this;
  }

  getCurrency(): CurrencyEnum {
    return this.currency;
  }

  setCurrency(currency: CurrencyEnum): SourcingRequest {
    this.currency = currency;
    return this;
  }

  setCurrencyUpdateTime(updateDate: Date): SourcingRequest {
    this.currencyUpdateTime = updateDate;
    return this;
  }

  getCurrencyUpdateTime(): Date {
    return this.currencyUpdateTime;
  }

  getCurrencyRate(): number {
    return this.currencyRate;
  }

  setCurrencyRate(rate: number): SourcingRequest {
    this.currencyRate = rate;
    return this;
  }

  setAllowFirmDeparture(value: boolean): SourcingRequest {
    this.allowFirmDeparture = value;

    return this;
  }

  getAllowFirmDeparture(): boolean {
    return this.allowFirmDeparture;
  }

  getSearchResultOptions(): Array<SearchResultOption> {
    return this.searchResultOptions;
  }

  setSearchResultOptions(options: Array<SearchResultOption>): SourcingRequest {
    this.searchResultOptions = options;
    return this;
  }

  getAdditionalInstructions(): string {
    return this.additionalInstructions;
  }

  setAdditionalInstructions(additionalInstructions: string): SourcingRequest {
    this.additionalInstructions = additionalInstructions;

    return this;
  }

  getQuoteDescription(): string {
    return this.quoteDescription;
  }

  setQuoteDescription(quoteDescription: string): SourcingRequest {
    this.quoteDescription = quoteDescription;
    
    return this;
  }

  getChildSourcingRequests(): Array<SourcingRequest> {
    return this.childSourcingRequests;
  }

  setChildSourcingRequests(childSourcingRequests: Array<SourcingRequest>): SourcingRequest {
    this.childSourcingRequests = childSourcingRequests;

    return this;
  }

  getSource(): string {
    return this.source;
  }

  setSource(source: string): SourcingRequest {
    this.source = source;

    return this;
  }

  getFulfillmentStage(): FulfillmentStagesEnum {
    return this.fulfillmentStage;
  }

  setFulfillmentStage(fulfillmentStage: FulfillmentStagesEnum): SourcingRequest {
    this.fulfillmentStage = fulfillmentStage;

    return this;
  }

  getIsModeratedOffersSent(): boolean {
    return this.isModeratedOffersSent;
  }

  setIsModeratedOffersSent(isModeratedOffersSent: boolean): SourcingRequest {
    this.isModeratedOffersSent = isModeratedOffersSent;
    
    return this;
  }

  getRtpRequestStages(): Array<RtpRequestStage> {
    return this.rtpRequestStages;
  }

  setRtpRequestStages(rtpRequestStages: Array<RtpRequestStage>): SourcingRequest {
    this.rtpRequestStages = rtpRequestStages;

    return this;
  }
}
