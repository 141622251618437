import { Operator } from '../../domain/models/operator';

export class Lookup {
  skip = 0;
  limit = 15;
  total = 0;
  active: boolean = null;
  searchTerm: string = null;
  operator: Operator = new Operator();
}
