import { createSelector } from 'reselect';
import { ActionReducer, combineReducers } from '@ngrx/store';

import * as fromSearchResults from './search-results';
import * as fromOperator from './operator';

export interface State {
  searchResults: fromSearchResults.State;
  operator: fromOperator.State;
}

const reducers = {
  searchResults: fromSearchResults.reducer,
  operator: fromOperator.reducer
};

const combinedReducer: ActionReducer<State> = combineReducers(reducers);

export function reducer(state: any, action: any): any {
  return combinedReducer(state, action);
}

export const getSearchResultsState = (state: any) => state.explorer.searchResults;
export const getSearchResultsLookup = createSelector(
  getSearchResultsState,
  fromSearchResults.getLookup
);

export const getSearchResultsVistaStudioItems = createSelector(
  getSearchResultsState,
  fromSearchResults.getVistaStudioItems
);
export const getSearchResultsVistaStudioItemsLoading = createSelector(
  getSearchResultsState,
  fromSearchResults.getVistaStudioItemsLoading
);
export const getSearchResultsVistaStudioItemsLoaded = createSelector(
  getSearchResultsState,
  fromSearchResults.getVistaStudioItemsLoaded
);
export const getSearchResultsMarketplaceItems = createSelector(
  getSearchResultsState,
  fromSearchResults.getMarketplaceItems
);
export const getSearchResultsMarketplaceItemsLoading = createSelector(
  getSearchResultsState,
  fromSearchResults.getMarketplaceItemsLoading
);
export const getSearchResultsMarketplaceItemsLoaded = createSelector(
  getSearchResultsState,
  fromSearchResults.getMarketplaceItemsLoaded
);


export const getOperatorState = (state: any) => state.explorer.operator;
export const getOperatorCancellationTerms = createSelector(
  getOperatorState,
  fromOperator.getOperatorCancellationTerms
);
export const getOperatorCancellationTermsIsLoading = createSelector(
  getOperatorState,
  fromOperator.getOperatorCancellationTermsIsLoading
);
export const getOperatorCancellationTermsIsLoaded = createSelector(
  getOperatorState,
  fromOperator.getOperatorCancellationTermsIsLoaded
);