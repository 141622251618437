import * as actions from '../actions/operator';
import * as _ from 'lodash';

export interface State {
    cancelationTerms: string;
    cancelationTermsLoading: boolean;
    cancelationTermsLoaded: boolean;
}

const initialState: State = {
    cancelationTerms: null,
    cancelationTermsLoading: false,
    cancelationTermsLoaded: false,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.GET_OPERATOR_CANCELLATION_TERMS: {
      return { 
        ...state,
        cancelationTermsLoaded: false,
        cancelationTermsLoading: true,
        
      };
    }

    case actions.GET_OPERATOR_CANCELLATION_TERMS_SUCCESS: {
      return { 
        ...state,
        cancelationTerms: action.payload.cancellationTerms,
        cancelationTermsLoaded: true,
        cancelationTermsLoading: false,
        
      };
    }

    case actions.GET_OPERATOR_CANCELLATION_TERMS_FAILED: {
        return { 
            ...state,
            cancelationTerms: null,
            cancelationTermsLoaded: false,
            cancelationTermsLoading: false,
            
        };
    }

    default: {
      return state;
    }
  }
}

export const getOperatorCancellationTerms = (state: State) => state.cancelationTerms;
export const getOperatorCancellationTermsIsLoading = (state: State) => state.cancelationTermsLoading;
export const getOperatorCancellationTermsIsLoaded = (state: State) => state.cancelationTermsLoaded;
