import * as models from '../../domain/models';
import * as actions from '../actions/aircraft-detail';
import * as _ from 'lodash';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
  item: models.Aircraft;
  pristine: boolean;
  editMode: boolean;
  editActionNew: boolean;
  wyvernData: models.AircraftWyvern;
  isWyvernDataLoaded: boolean;
  isWyvernDataLoading: boolean;
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  item: new models.Aircraft(),
  pristine: true,
  editMode: false,
  editActionNew: false,
  wyvernData: null,
  isWyvernDataLoaded: false,
  isWyvernDataLoading: false,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    }

    case actions.LOAD_SUCCESS: {
      const item = _.cloneDeep(action.payload.item);
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        pristine: true,
        item,
      };
    }

    case actions.LOAD_FAIL: {
      return {
        ...state,
        isLoaded: true,
      };
    }

    case actions.RESET: {
      return {...initialState}
    }

    case actions.BYPASS_LOAD: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
      };
    }

    case actions.SET: {
      let pristine = _.cloneDeep(state.pristine);
      const compare1 = _.cloneDeep(state.item);
      const compare2 = _.cloneDeep(action.payload.item);
      delete compare1.deleted;
      delete compare2.deleted;
      if (!_.isEqual(compare1, compare2)) {
        pristine = false;
      }

      return {
        ...state,
        pristine,
        item: _.cloneDeep(action.payload.item),
        isLoaded: true,
      };
    }
    case actions.SAVE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actions.SAVE_SUCCESS: {
      return {
        ...state,
        pristine: true,
        isLoading: false,
        editMode: false,
        editActionNew: false,
      };
    }

    case actions.SAVE_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case actions.SET_EDIT_MODE: {
      return {
        ...state,
        editMode: action.payload
      }
    }

    case actions.SET_EDIT_ACTION_NEW: {
      return {
        ...state,
        editActionNew: action.payload
      }
    }

    case actions.LOAD_WYVERN: {
      return {
        ...state,
        isWyvernDataLoaded: false,
        isWyvernDataLoading: true
      }
    }

    case actions.LOAD_WYVERN_SUCCESS: {
      return {
        ...state,
        isWyvernDataLoaded: true,
        isWyvernDataLoading: false,
        wyvernData: _.cloneDeep(action.payload)
      }
    }

    case actions.LOAD_WYVERN_FAIL: {
      const wyvernData = new models.AircraftWyvern();
      wyvernData.setTailNumber(action.payload);
      return {
        ...state,
        isWyvernDataLoading: false,
        isWyvernDataLoaded: true,
        wyvernData: wyvernData
      }
    }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getItem = (state: State) => state.item;
export const getPristine = (state: State) => state.pristine;
export const getEditMode = (state: State) => state.editMode;
export const getEditActionNew = (state: State) => state.editActionNew;
export const getWyvernData = (state: State) => state.wyvernData;
export const getWyvernDataLoading = (state: State) => state.isWyvernDataLoading;
export const getWyvernDataLoaded = (state: State) => state.isWyvernDataLoaded;
