import { SortDescriptor } from '@progress/kendo-data-query';
import { Lookup } from './lookup';

export class AircraftLookup extends Lookup {
  insApproveOnly: boolean = null;
  completed: boolean = null;
  approvedBy: string = null;
  aircraftCategory: number = null;
  homeBaseCountry: string = null;
  homeBaseServiceAreas: Array<string> = [];
  onFleet: boolean = null;
  homeBase: string = null;
  active = true;
  d085OpsSpecsApproval: boolean = null;
  marketplacePhotosApproval: boolean = null;
  aircraftAge: number = null;
  refurbishmentAge: number = null;
  sort: Array<SortDescriptor> = [];
}
