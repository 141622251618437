import { CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";

export class VendorOffersLookup {
    skip = 0;
    limit = 15;
    total = 0;
    sort: Array<SortDescriptor> = [{field: 'createdDate', dir: 'desc'}];
    filter: CompositeFilterDescriptor = null;
    vendorId: string = null;
  }
  