import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const LOAD = '[RM Airport Detail] Load';
export const LOAD_SUCCESS = '[RM Airport Detail] Load Success';
export const LOAD_FAIL = '[RM Airport Detail] Load Fail';
// export const SAVE = '[RM Airport Detail] Save';
// export const SAVE_SUCCESS = '[RM Airport Detail] Save Success';
// export const SAVE_FAIL = '[RM Airport Detail] Save Fail';

export class LoadPayload {
  constructor(public code: string) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(public item: models.Airport) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

// export class SaveAction implements Action {
//   readonly type = SAVE;
// }

// export class SaveSuccessPayload {
//   constructor(public item: models.Airport) {
//   }
// }

// export class SaveSuccessAction implements Action {
//   readonly type = SAVE_SUCCESS;

//   constructor(public payload: SaveSuccessPayload) {
//   }
// }

// export class SaveFailAction implements Action {
//   readonly type = SAVE_FAIL;
// }

export type Actions = LoadAction | LoadSuccessAction | LoadFailAction;
// | SaveAction
// | SaveSuccessAction
// | SaveFailAction;
