import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[RM AIRPORT Aircraft] Load';
export const LOAD_SUCCESS = '[RM AIRPORT Aircraft] Load Success';
export const LOAD_FAIL = '[RM AIRPORT Aircraft] Load Fail';
export const RESET = '[RM AIRPORT Aircraft] Reset';

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: string) {}
}


export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: Array<models.Aircraft>) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class ResetAction implements Action {
  readonly type = RESET;
}

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | ResetAction;
